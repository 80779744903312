import Layout from '@components/layout/Layout';
import Container from '@components/ui/container/Container';
import Footer from '@components/ui/footer/Footer';
import Header from '@components/ui/header/Header';
import TitleBar from '@components/ui/title-bar/TitleBar';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import LanguageProvider from 'lib/language.provider';
import React from 'react';

const PolitykaPrywatnosciPage = ({ pageContext, location }) => {
	i18n.activate(pageContext.language);

	return (
		<LanguageProvider>
			<Layout>
				<main className="min-h-screen">
					<Header location={location.pathname} />
					<div className="flex flex-col min-h-screen">
						<TitleBar>
							<div className="z-10 pt-10 w-full text-white md:max-w-xl lg:max-w-2xl">
								<h1 className="font-serif text-4xl font-bold">
									<Trans>Polityka Prywatności</Trans>
								</h1>
							</div>
						</TitleBar>
						<div>
							<Container>
								<div className="py-14 max-w-3xl">
									<ol className="list-decimal pl-8 space-y-5 text-sm">
										<li>
											<h2 className="font-bold text-base mb-4">
												ADMINISTRATOR
											</h2>
											<p>
												Administratorem danych osobowych odpowiedzialnym za ich
												przetwarzanie jest:{' '}
												<span className="whitespace-nowrap">
													Iwanejko–Taczkowska Legal & Advisory
												</span>
												<br />
												Gdynia ul. Batorego 28-32 lok. 107,
												<br />
												<span className="whitespace-nowrap">
													e-mail: katarzyna@iwanejkotaczkowska.pl
												</span>
											</p>
										</li>
										<li>
											<h2 className="font-bold text-base mb-4">
												CELE ORAZ PODSTAWY PRAWNE PRZETWARZANIA DANYCH
											</h2>
											<h3 className="font-bold">FORMULARZ KONTAKTOWY</h3>
											<p className="mb-4">
												Kancelaria zapewnia możliwość skontaktowania się przy
												wykorzystaniu elektronicznego formularza kontaktowego.
											</p>
											<p className="mb-4">
												Skorzystanie z formularza wymaga podania danych
												osobowych niezbędnych do udzielenia odpowiedzi na zadane
												pytanie lub przekazanie informacji w procesie
												rekrutacji.
											</p>
											<p className="mb-4">
												Dane osobowe są przetwarzane w celu:
											</p>
											<ul className="list-disc mb-4 pl-4">
												<li>
													udzielenia odpowiedzi na zapytanie przesłane przez
													udostępniony formularz – podstawą prawną przetwarzania
													jest niezbędność przetwarzania do udzielenia
													odpowiedzi (art. 6 ust. 1 lit. b i f RODO);
												</li>
												<li>
													przekazanie informacji w procesie rekrutacji -
													podstawą prawną przetwarzania jest zgoda wyrażona
													przez osobę, której dane dotyczą (art. 6 ust. 1 lit. a
													RODO).
												</li>
											</ul>
											<h3 className="font-bold">DANE KLIENTÓW</h3>
											<p className="mb-4">
												Klienci Kancelarii przekazują dane osobowe ich dotyczące
												w celu realizacji przez Kancelarię umowy o świadczenie
												usług prawnych.
											</p>
											<p className="mb-4">
												Dane osobowe są przetwarzane w celu:
											</p>
											<ul className="list-disc mb-4 pl-4">
												<li>
													realizacji umowy o świadczenie usług prawnych –
													podstawą prawną przetwarzania jest niezbędność
													przetwarzania oraz prawnie uzasadnionych interesów
													administratora (art. 6 ust. 1 lit. b i f RODO);
												</li>
											</ul>
										</li>
										<li>
											<h2 className="font-bold text-base mb-4">
												PLIKI COOKIES
											</h2>
											<p>
												Pliki cookies to małe pliki tekstowe instalowane na
												urządzeniu użytkownika przeglądającego stronę
												internetową Kancelarii. Kancelaria nie wykorzystuje
												żadnych rodzajów plików cookies.
											</p>
										</li>
										<li>
											<h2 className="font-bold text-base mb-4">
												OKRES PRZETWARZANIA DANYCH OSOBOWYCH
											</h2>
											<p className="mb-4">
												Okres przetwarzania danych przez Kancelarię zależy od
												celu przetwarzania. Co do zasady dane przetwarzane są
												przez czas świadczenia usługi, w odniesieniu do której
												udzielona została odpowiedź na pytanie zadane przez
												formularz kontaktowy, do czasu wycofania wyrażonej zgody
												lub zgłoszenia skutecznego sprzeciwu względem
												przetwarzania danych w przypadkach, gdy podstawą prawną
												przetwarzania danych jest uzasadniony interes
												administratora.
											</p>
											<p className="mb-4">
												Okres przetwarzania danych może być przedłużony w
												przypadku, gdy przetwarzanie jest niezbędne do ustalenia
												i dochodzenia ewentualnych roszczeń lub obrony przed
												nimi, a po tym czasie jedynie w przypadku i w zakresie,
												w jakim będą wymagać tego przepisy prawa. Po upływie
												okresu przetwarzania dane są nieodwracalnie usuwane lub
												anonimizowane.
											</p>
										</li>
										<li>
											<h2 className="font-bold text-base mb-4">
												UPRAWNIENIA OSOBY, KTÓREJ DANE DOTYCZĄ
											</h2>
											<p className="mb-4">
												Osobom, których dane dotyczą, przysługują następujące
												prawa:
											</p>
											<ul className="list-disc mb-4 pl-4">
												<li>
													prawo do informacji o przetwarzaniu danych – art. 15
													RODO
												</li>
												<li>prawo uzyskania kopii danych – art. 15 RODO</li>
												<li>prawo do sprostowania – art. 16 RODO</li>
												<li>prawo do usunięcia danych – art.17 RODO</li>
												<li>
													{' '}
													prawo do ograniczenia przetwarzania – art. 18 RODO
												</li>
												<li>prawo do przenoszenia danych – art.20 RODO</li>
												<li>prawo do skargi – art. 77 RODO</li>
												<li>prawo sprzeciwu – art. 21 RODO</li>
											</ul>
											<p className="mb-4">
												Jeżeli w sposób opisany w niniejszej polityce
												prywatności przetwarzamy dane osobowe w celu
												zabezpieczenia naszych prawnie uzasadnionych interesów,
												wówczas osoba, której dane dotyczą może wyrazić sprzeciw
												wobec przetwarzania jej danych w tym celu ze skutkiem na
												przyszłość.
											</p>
											<p className="mb-4">
												Po skorzystaniu przez użytkownika z prawa do sprzeciwu
												administrator nie będzie kontynuować przetwarzania
												danych osobowych użytkownika, chyba że wykaże istnienie
												ważnych, prawnie uzasadnionych podstaw do przetwarzania
												i będą one nadrzędne wobec interesów i praw osoby,
												której dane dotyczą, lub gdy przetwarzanie danych ma
												służyć dochodzeniu, wykonaniu lub obronie roszczeń
												prawnych.
											</p>
											<p className="mb-4">
												Wniosek dotyczący realizacji praw podmiotów danych,
												można złożyć:
											</p>
											<ul className="list-disc mb-4 pl-4">
												<li>
													w formie pisemnej na adres: Iwanejko – Taczkowska
													Legal & Advisory Gdynia ul. Batorego 28-32 lok. 107
												</li>
												<li>
													drogą e-mailową na adres:
													katarzyna@iwanejkotaczkowska.pl
												</li>
											</ul>
										</li>
										<li>
											<h2 className="font-bold text-base mb-4">
												ODBIORCY DANYCH
											</h2>
											<p className="mb-4">
												W związku z realizacją usług dane osobowe mogą być
												ujawniane następującym grupom podmiotów zewnętrznych:
												dostawcom odpowiedzialnym za obsługę systemów
												informatycznych Kancelarii, bankom i operatorom
												płatności oraz podmiotom świadczącym usługi księgowe.
											</p>
											<p className="mb-4">
												Kancelaria nie planuje przekazywać danych osobowych do
												państwa trzeciego, tj. poza Europejski Obszar
												Gospodarczy obejmujący państwa członkowskie Unii
												Europejskiej, Norwegię, Islandię, Liechtenstein, ani do
												organizacji międzynarodowej.
											</p>
										</li>
									</ol>
								</div>
							</Container>
						</div>
						<Footer />
					</div>
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default PolitykaPrywatnosciPage;
